header{
    background: url("../media/images/bg3.jpg");
    padding: 40px 0;
    background-position: bottom left;
    background-size: cover;
}

.header{
    max-width: 1250px;
    margin: auto;
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 50px;
}

.header-options-heading{
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    text-transform: capitalize;
    padding-top: 30px;
}
.header-options-heading>span{
    color: #3867be;
}




/* ---------------------------------- */

.header-options-content{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px 50px;
    align-content: center;
    height: 100%;
    margin-top: -50px;
    padding: 20px;
}

.header-options-content-col{
    box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;

    background-color: #f0f5ff;
padding: 20px;
border-radius: 30px;
text-align: center;
}

.header-options-content>a{
    color: black;
    text-decoration: none;
}
.header-options-content>a:nth-child(1){
    animation: wiggle 2s linear;
    animation-delay: 2s;
}

.header-options-content>a:nth-child(2){
    animation: wiggle 2s linear;
    animation-delay: 4s;
}

.header-options-content>a:nth-child(3){
    animation: wiggle 2s linear;
    animation-delay: 6s;
}

.header-options-content>a:nth-child(4){
    animation: wiggle 2s linear;
    animation-delay: 8s;
}

.header-options-content>a:nth-child(5){
    animation: wiggle 2s linear;
    animation-delay: 10s;
}

.header-options-content>a:nth-child(6){
    animation: wiggle 2s linear;
    animation-delay: 12s;
}

.header-options-content-col-icon>img{
    width: 110px;
}
.header-options-content-col-text{
    font-weight: 500;
}

/* ---------------------------------------- */

.header-form{
    background-color: rgb(255, 255, 255);
    box-shadow: 4px 4px 6px 0 #96969680,-4px -4px 6px 0 #92929236,inset -4px -4px 6px 0 #3e3e3e33,inset 4px 4px 6px 2px hsla(0,0%,43%,.201);
    border-radius: 20px;
    padding: 20px;
    display: grid;
    gap: 30px;
}


.header-form-icon{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    text-align: center;
    font-size: .8rem;
}

.header-form-icon-col-icon>img{
    width: 40px;
}

.header-form>form{
    display: grid;
    gap: 10px;
}
.header-form>form>input{
    border: solid rgb(196, 196, 196) 1px;
    border-radius: 5px;
    padding: 8px;
    font-size: 1rem;
}
.header-form>form>button{
    background-color: #fe7a01;
    background-color: #2394d1;
    background-color: #3867be;
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: white;
    font-size: 1.1rem;
    font-weight: 600;
}



/* -------------------------------------------- */
.header-rating{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    margin-top: -20px;
}
.header-rating>div:nth-child(1){
    border-right: solid gray 2px;
}

.header-rating-col-heading{
    font-size: 1.5rem;
    font-weight: 600;
}
.header-rating-col-text{
    font-size: .9rem;
    line-height: 1rem;
    color: gray;
}


/* -------------------------------------------- */

.header-form-end{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
}

.header-form-end-col-icon>img{
    height: 40px;
    
}
.header-form-end-col-text{
    font-size: .9rem;
    line-height: 1rem;
    color: rgb(67, 67, 67);
}


@media only screen and (max-width: 800px) {
    header{
        padding: 20px;
    }
    
    .header{
        grid-template-columns: 1fr ;
        gap: 50px;
    }
    
    .header-options-heading{
        font-size: 1.5rem;
        padding-top: 0px;
    }
    
    
    
    
    /* ---------------------------------- */
    
    .header-options-content{
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        height: auto;
        margin-top: 0px;
        padding: 10px 0px;
    }
    
    .header-options-content-col{
    padding: 15px;
    border-radius: 15px;
    }
    
    
    .header-options-content-col-icon>img{
        width: 80px;
    }
    .header-options-content-col-text{
        font-weight: 500;
        /* font-size: 1.1rem; */
    }
    
    /* ---------------------------------------- */
    

    
    
    .header-form-icon{
        gap: 5px;
    }
    
    
  
    
    /* -------------------------------------------- */
 

    .header-rating-col-heading{
        font-size: 1.3rem;
        font-weight: 600;
    }
    .header-rating-col-text{
        font-size: .8rem;
        line-height: 1rem;
        color: gray;
    }
    
    
    /* -------------------------------------------- */
    

    .header-form-end-col-icon>img{
        height: 35px;
        
    }
    .header-form-end-col-text{
        font-size: .8rem;
        line-height: 1rem;
    }
    
    
    
}
